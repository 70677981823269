@import '../../RbKit/config';

.tag {
    background: $colorRocheBlue;
    border-radius: 4px;
    color: $colorWhite;
    display: inline-block;
    font-size: .75rem;
    margin-right: .5rem;
    margin-top: .5rem;
    padding: 3px .5rem;

    svg {
        color: $colorWhite;
        cursor: pointer;
        margin-left: .5rem;
    }
}
