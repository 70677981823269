@import '../../RbKit/config';

.container {
    color: #000000;
    font-family: "Roche Sans Light", "Helvetica Neue", "Arial", sans-serif !important;
    font-weight: 300;
    margin: 68px auto;
    max-width: 90%;
    width: 500px;

    h1, h2 {
        align-items: center;
        display: flex;
        font-size: 1.5rem;
        
        svg {
            color: $colorRocheBlue;
            cursor: pointer;
            margin-left: 1rem;
            position: relative;
            top: -1px;
        }
    }

    p {
        font-size: 1rem;
    }
}

.logo {
    img {
        display: block;
        margin: 24px;
        width: 72px;
    }
}

.topic {
    border-bottom: solid 1px $colorGrey10;
    cursor: pointer;
    align-items: flex-start;
    display: flex;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    .topicCheck {

    }

    .topicInfo {
        flex: 1;
        
        h3 {
            font-size: 1rem;
            margin: 0;
        }

        p:last-child {
            font-size: 14px;
            margin-bottom: 0;
        }
    }
}

.form {
    margin-bottom: 2rem;

    .mid {
        margin-bottom: 1.25rem;
        @include landscape {
            margin: 0;
            padding: 0;
        }
    }
}

.info {
    color: $colorGrey60Alpha;
    font-size: .75rem !important;
    line-height: 1rem;
    margin: 0;
    font-style: italic;
}

.moblab {
    display: block;
    @include portrait {
        display: inline;
    }
}
