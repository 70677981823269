@import '../../config';

.base {
    background: $colorWhite;
    margin-bottom: 2rem;
    padding: 2rem;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }

    > h1, > h2, > h3, > h4, > h5, > h6 {
        margin-bottom: 1.5rem;
    }

    .header {
        align-items: center;
        display: flex;
        justify-content: space-between;

        h2 {
            font-size: 1.5rem;
        }
    }
}

.densePadding { padding: .5rem; }
.compactPadding { padding: 1rem; }
.defaultPadding { padding: 2rem; }
.nonePadding { padding: 0; }
.paddedPadding { padding: 3rem; }

.elevate1 { @include elevate(1) }
.elevate2 { @include elevate(2) }
.elevate3 { @include elevate(3) }
.elevate4 { @include elevate(4) }
.elevate5 { @include elevate(5) }
.elevate6 { @include elevate(6) }
