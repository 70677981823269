.container {
    &.cms {
        > div {
            color: #3c3c3c;
            max-width: 1040px;
            margin: 0 auto;
        }
    }

    h1 {
        margin: 0 0 32px 0;
        font-size: 36px;
    }

    a {
        color: #4baaf4;
    }

    p, ul, ol {
        font-size: 18px;
        line-height: 1.7;
        margin-bottom: 32px;
        font-weight: 300;

        // &:last-child {
        //     margin-bottom: 0;
        // }
    }

    h2 {
        font-size: 18px;
        line-height: 1.9;
        margin-bottom: 0;
    }


    p + ul, p + ol {
        margin-top: -24px;
    }

    ul {
        list-style: none;

        li:before {
            content: '\2022';
            color: #4baaf4; /* Change the color */
            font-weight: bold;
            font-size: 20px;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
            top: 2px;
            position: relative;
        }
    }

    .infobox {
        display: flex;
        align-items: flex-start;
        background-color: rgba(75, 170, 244, .1);
        border: solid 1px #4baaf4;
        border-radius: 6px;
        padding: 2rem;

        > svg {
            font-size: 24px;
            color: #4baaf4;
            position: relative;
            top: 5px;
            margin-right: 2rem;
        }

        p {
            font-size: 16px;
        }

        p, ul, ol {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
