@import '../../config';

.base {
    background: #f5f6fa;
    border-radius: 2px;
    height: .5rem;
    width: 100%;

    > div {
        background-color: $colorRocheBlue;
        border-radius: 2px;
        height: 100%;
        width: 0;
    }
}
