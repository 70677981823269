@mixin elevate($lvl, $opacity: 10) {
    $color: rgba(0, 102, 204, ($opacity / 100));

    @if $lvl == 1 { box-shadow: 0 0 2px $color; }
    @if $lvl == 2 { box-shadow: 0 1px 4px $color; }
    @if $lvl == 3 { box-shadow: 0 1px 8px $color; }
    @if $lvl == 4 { box-shadow: 0 2px 10px $color; }
    @if $lvl == 5 { box-shadow: 0 4px 10px $color; }
    @if $lvl == 6 { box-shadow: 0 8px 14px $color; }
}
