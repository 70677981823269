@import '../../config';

.input {
    background-color: $colorWhite;
    border: solid 1px $colorGrey20;
    border-radius: 2px;
    color: $colorGrey90Alpha;
    display: block;    
    font-size: 1rem;
    font-weight: 400;
    height: 3.5rem;
    line-height: 1.25rem;
    padding: 1.125rem 1rem 0 1rem;
    transition:
        border-color 150ms linear,
        box-shadow 150ms linear;
    width: 100%;

    &:hover {
        border-color: $colorBlue60;
    }

    &:focus {
        border-color: $colorRocheBlue;
    }

    &::placeholder {
        color: #b6c6e3;
        font-weight: 500;
    }

    &.error {
        border-color: $colorError;
    }

    &.withIcon {
        padding-left: 2.75rem;
    }
}

.base {
    position: relative;
    
    > *:first-child:not(input) {
        font-size: 1rem;
        left: 1rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &.transparent {
        svg {
            left: 0;
        }

        .input {
            border: 0;
            box-shadow: none;
            padding-left: 0;
            padding-right: 0;

            &.withIcon {
                padding-left: 1.75rem;
            }
        }
    }
}
