@import './RbKit/config';

.main {
    height: calc(100vh - 4rem);
    margin-top: 4rem;
    overflow: auto;
    padding: 2rem 1rem;
    transition: margin 200ms ease-out;
    @include portrait {
        padding: 2rem;
    }
    @include landscape {
        margin-left: 290px;

        &.isCollapsed {
            margin-left: 0;
        }
    }

    > div {
        margin: 0 auto;
        max-width: 1200px;
    }
}

.mainFront {
    height: 100vh;
    overflow: auto;
}
