@import '../../../RbKit/config';

.status {
    display: block;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    background: #b0b0b0;

    &--1 {
        background-color: $colorSuccess;
    }
    &--2 {
        background-color: $colorError;
    }
}
