@import '../../RbKit/config';

.button {
    @include alignCenter;
    padding: 2rem;
}

.blockContainer {
    border: dashed 1px $colorGrey30;
    margin-bottom: 2rem;
    padding: 1.5rem 1rem 1rem 1rem;
    position: relative;

    .blockContainerActions {
        display: flex;
        position: absolute;
        right: 1rem;
        transform: translateY(-50%);
        top: 0;

        > span {
            @include fullCenter;
            cursor: pointer;
            height: 2rem;
            margin: 0 2px;
            transition: background 150ms linear;
            width: 2rem;

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                background-color: $colorBlue5;
            }

            &.more {
                border-left: solid 1px rgba(0, 0, 0, .1);
                margin-right: 0;
                padding: 0 .5rem;
                width: auto;

                svg + span {
                    margin-left: .5rem;
                }

                span {
                    color: rgba(0, 0, 0, .3);
                }
            }
        }
    }
}

.inlineContentEditor {
    display: flex;
}

.modalBlocks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -.5rem;

    .modalBlock {
        flex-basis: 150px;

        > div {
            @include elevate(2);
            border-radius: 2px;
            border: solid 1px $colorGrey5;
            cursor: pointer;
            align-items: center;
            display: flex;
            flex-direction: column;
            font-size: 1rem;
            font-weight: 400;
            height: 150px;
            margin: .5rem;
            justify-content: center;
            padding: .5rem;
            transition: background 150ms linear;
            width: 150px;

            svg {
                font-size: 2.5rem;
                margin-bottom: 1rem;
            }

            &:hover {
                background-color: $colorBlue5;
            }
        }
    }
}

.sidebar {
    @include elevate(6);
    background: $colorWhite;
    height: 100vh;
    left: -600px;
    overflow: auto;
    padding: 6rem 1rem;
    position: fixed;
    top: 0;
    transition: left 200ms ease-in-out;
    width: 33.33334%;
    min-width: 400px;
    max-width: 600px;
    z-index: 90;

    .sidebarTitle {
        align-items: center;
        display: flex;
        justify-content: space-between;

        h2 {
            flex: 1;
            margin: 0 0 2rem;
        }
    }

    .sidebarField {
        margin-bottom: 2rem;
    }

    .sidebarFooter {
        @include alignRight;
        display: none;
        background: $colorWhite;
        bottom: 0;
        left: 0;
        padding: 1rem;
        position: fixed;
        width: 33.33334%;
        min-width: 400px;
        max-width: 600px;
        z-index: 2;
    }

    &.show {
        left: 0;

        .sidebarFooter {
            display: flex;
        }
    }
}

.sidebarOverlay {
    background-color: rgba(255, 255, 255, .8);
    bottom: 0;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition:
        opacity 150ms linear,
        visibility 0s linear 150ms;
    visibility: hidden;
    width: 100vw;
    z-index: 89;

    &.show {
        opacity: 1;
        transition-delay: 0s;
        visibility: visible;
    }
}
