@import '../../config';

.base {
    padding: .75rem 0;
    // margin-bottom: 1rem;
    &:last-child {
        margin-bottom: 0;
    }
}

.divider {
    background: $colorGrey10;
    border: 0;
    height: 1px;
    margin: 3px 0;
}

.header {
    align-items: center;
    color: $colorGrey60Alpha;
    display: flex;
    font-size: .75rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: .05em;
    height: 3rem;
    padding: 0 1rem;
    text-transform: uppercase;
}

.item {
    align-items: center;
    background-color: transparent;
    border-left: solid 5px transparent;
    color: $colorGrey90Alpha;
    display: flex;
    font-size: 1rem;
    height: 3rem;
    line-height: 1.25rem;
    padding: 0 1rem;
    text-decoration: none;
    transition:
        background-color 150ms linear,
        border-color 150ms linear,
        color 150ms linear;

    &:hover {
        background-color: $colorBlue10;
        text-decoration: none;
    }

    &.itemIsActive {
        border-left-color: $colorRocheBlue;
        color: $colorRocheBlue;
        font-weight: 500;
    }

    .itemIcon {
        font-size: 1.25rem;
        margin-right: .75rem;
        text-align: center;
        width: 2rem;
    }

    .itemContent {
        flex: 1;
    }
}

.dropdownContainer {
    opacity: 0;
    position: absolute !important;
    right: 0;
    visibility: hidden;
    z-index: 101;
}

.dropdownContainerIsOpen {
    opacity: 1;
    visibility: visible;
}

.dropdown {
    min-width: 180px;
    padding: .5rem 0;

    .item {
        color: $colorGrey90Alpha;
        cursor: pointer;
        font-size: .75rem;
        font-weight: 400;
        line-height: 1;
        padding: .625rem 1.25rem .625rem .75rem;
        text-decoration: none;
        transition:
            background-color 150ms linear,
            color 150ms linear;
    
        &:hover {
            background-color: #f5f6fa;
            color: $colorRocheBlue;
            text-decoration: none;
        }
    
        .itemIcon {
            font-size: 1rem;
            margin-right: .5rem;
            width: 1.75rem;
        }
    }
}

.tabs {
    display: flex;

    .item {
        background: $colorWhite;
        border: solid 1px $colorGrey20;
        border-right: 0;
        margin-bottom: 0;
        padding: .5rem 1rem;
        height: auto;
        cursor: pointer;
        font-weight: normal;

        &:hover {
            color: $colorRocheBlue;
            text-decoration: none;
        }
    
        &.itemIsActive {
            background-color: $colorRocheBlue;
            color: #ffffff;
        }

        &:first-child {
            border-bottom-left-radius: $borderRadius;
            border-top-left-radius: $borderRadius;
        }

        &:last-child {
            border-right: solid 1px $colorGrey20;
            border-bottom-right-radius: $borderRadius;
            border-top-right-radius: $borderRadius;
        }
    }
}
