@import '../../../RbKit/config';

.container {
    > div {
        float: left;
    }
}

.modalUploadButton {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0 0 0;
}

.folders {
    display: flex;
    flex-wrap: wrap;

    .folder {
        align-items: flex-start;
        background: #ffffff;
        box-shadow:  0 2px 6px 0 rgba(0,102,204,.1);
        cursor: pointer;
        display: flex;
        margin: 0 1rem 1rem 0;
        padding: .5rem 1rem;
        transition: all 150ms linear;

        > svg {
            margin-right: 1rem;
            font-size: 20px;
        }

        &:hover {
            background-color: $colorBlue20;
            box-shadow: none;
        }
    }
}
