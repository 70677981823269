@import '../../config';

.overlay {
    background-color: rgba(255, 255, 255, .8);
    bottom: 0;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition:
        opacity 150ms linear,
        visibility 0s linear 150ms;
    visibility: hidden;
    width: 100vw;
    z-index: 1000;

    &.showModal {
        opacity: 1;
        transition-delay: 0s;
        visibility: visible;
    }
}

.modal {
    @include elevate(6);
    background-color: $colorWhite;
    left: 50%;
    margin: 8% auto;
    width: 90%;
    position: absolute;
    transform: translateX(-50%);
    white-space: initial;

    &.small {
        width: 420px;
    }

    &.medium {
        width: 750px;
    }

    &.large {
        width: 960px;
    }

    .body {
        padding: 2rem;
    }

    .footer {
        padding: 1rem;
    }

    .header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 1rem;

        > div {
            flex: 1;
        }

        .title {
            margin-bottom: 0;
        }
    }
}
