@import '../../config.scss';

.base {
    padding: 0 1rem;
}

.column {
    margin-bottom: 2rem;
    flex-basis: 100%;
    padding: 0 1rem;

    &.flex {
        display: flex;
    }

    &.xs-12 { flex: 0 0 100%; }
    &.xs-11 { flex: 0 0 91.66666667%; }
    &.xs-10 { flex: 0 0 83.33333333%; }
    &.xs-9 { flex: 0 0 75%; }
    &.xs-8 { flex: 0 0 66.66666667%; }
    &.xs-7 { flex: 0 0 58.33333333%; }
    &.xs-6 { flex: 0 0 50%; }
    &.xs-5 { flex: 0 0 41.66666667%; }
    &.xs-4 { flex: 0 0 33.33333333%; }
    &.xs-3 { flex: 0 0 25%; }
    &.xs-2 { flex: 0 0 16.66666667%; }
    &.xs-1 { flex: 0 0 8.33333333%; }
    &.hiddenXs { display: none; }

    @include portrait {
        &.sm-12 { flex: 0 0 100%; }
        &.sm-11 { flex: 0 0 91.66666667%; }
        &.sm-10 { flex: 0 0 83.33333333%; }
        &.sm-9 { flex: 0 0 75%; }
        &.sm-8 { flex: 0 0 66.66666667%; }
        &.sm-7 { flex: 0 0 58.33333333%; }
        &.sm-6 { flex: 0 0 50%; }
        &.sm-5 { flex: 0 0 41.66666667%; }
        &.sm-4 { flex: 0 0 33.33333333%; }
        &.sm-3 { flex: 0 0 25%; }
        &.sm-2 { flex: 0 0 16.66666667%; }
        &.sm-1 { flex: 0 0 8.33333333%; }
        &.hiddenXs { display: inherit; }
        &.hiddenSm { display: none; }
    }

    @include landscape {
        &.md-12 { flex: 0 0 100%; }
        &.md-11 { flex: 0 0 91.66666667%; }
        &.md-10 { flex: 0 0 83.33333333%; }
        &.md-9 { flex: 0 0 75%; }
        &.md-8 { flex: 0 0 66.66666667%; }
        &.md-7 { flex: 0 0 58.33333333%; }
        &.md-6 { flex: 0 0 50%; }
        &.md-5 { flex: 0 0 41.66666667%; }
        &.md-4 { flex: 0 0 33.33333333%; }
        &.md-3 { flex: 0 0 25%; }
        &.md-2 { flex: 0 0 16.66666667%; }
        &.md-1 { flex: 0 0 8.33333333%; }
        &.hiddenXs { display: inherit; }
        &.hiddenSm { display: inherit; }
        &.hiddenMd { display: none; }
    }

    @include desktop {
        &.lg-12 { flex: 0 0 100%; }
        &.lg-11 { flex: 0 0 91.66666667%; }
        &.lg-10 { flex: 0 0 83.33333333%; }
        &.lg-9 { flex: 0 0 75%; }
        &.lg-8 { flex: 0 0 66.66666667%; }
        &.lg-7 { flex: 0 0 58.33333333%; }
        &.lg-6 { flex: 0 0 50%; }
        &.lg-5 { flex: 0 0 41.66666667%; }
        &.lg-4 { flex: 0 0 33.33333333%; }
        &.lg-3 { flex: 0 0 25%; }
        &.lg-2 { flex: 0 0 16.66666667%; }
        &.lg-1 { flex: 0 0 8.33333333%; }
        &.hiddenXs { display: inherit; }
        &.hiddenSm { display: inherit; }
        &.hiddenMd { display: inherit; }
        &.hiddenLg { display: none; }
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    &.noGutters {
        .column {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &.center {
        justify-content: center;
    }
}
