@import '../../RbKit/config';

.wrapper {
    height: calc(100vh - 4rem);
    overflow: hidden;
    position: fixed;
    top: 4rem;
    transition: width 200ms ease-out;
    width: 290px;
    z-index: 80;

    .base {
        max-height: calc(100vh - 4rem);
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar { display: none; }
    }

    &.isCollapsed {
        width: 0;
    }
}
