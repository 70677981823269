@import '../../config';

.base {
    align-items: center;
    background-color: rgba(255, 255, 255, .7);
    bottom: 0;
    display: flex;
    font-size: 2rem;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 199;

    &.card {
        border-radius: $borderRadius;
    }

    > svg {
        color: $colorRocheBlue;
    }
}
