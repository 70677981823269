@import '../../config';

.base {
    align-items: center;
    background: $colorDeepBlue95;
    border-left: solid .25rem $colorGrey90Alpha;
    border-radius: 2px;
    color: $colorWhite;
    display: flex;
    margin-bottom: 1.75rem;
    padding: .5rem 1rem;

    > div {
        color: $colorWhite70;
        flex: 1;
        font-size: 1rem;
        line-height: 1.25;
        padding-right: 1rem;

        h6 {
            color: $colorWhite;
            font-size: .875rem;
            font-weight: normal;
            margin: 0;
        }
    }

    > svg:first-child {
        color: $colorWhite;
        fill: $colorWhite;
        margin-right: 1rem;
        font-size: 1.25rem;
    }

    button svg {
        color: $colorWhite !important;
    }

    &.error {
        border-color: $colorError;
        // svg:first-child { color: $colorError; }
    }
    &.warning {
        border-color: $colorWarning;
        // svg:first-child { color: $colorWarning; }
    }
    &.success {
        border-color: $colorSuccess;
        // svg:first-child { color: $colorSuccess; }
    }

    &.fixed {
        bottom: 0;
        right: 1rem;
        position: fixed;
    }
}

.base + .base {
    margin-top: -1.25rem;
}
