@import '../../config';

.base {
    background-color: #ffffff;
    border: solid 1px transparent;
    border-radius: 2px;
    box-shadow:  0 2px 6px 0 rgba(0,102,204,.1);
    color: $colorRocheBlue;
    cursor: pointer;
    display: inline-block;
    font-size: .875rem;
    letter-spacing: .3;
    line-height: 1.25rem;
    padding: .5rem 1.25rem;
    text-align: center;
    text-decoration: none;
    transition:
        background-color 150ms linear,
        border-color 150ms linear,
        box-shadow 150ms linear,
        color 150ms linear;
    vertical-align: middle;

    svg:first-child + span {
        margin-left: 1rem;
    }

    &:active {
        box-shadow: none;
    }

    &:hover {
        background-color: $colorBlue5;
    }

    &.block {
        display: block;
        width: 100%;
    }

    &.disabled {
        opacity: .5;
    }

    &.link {
        background: transparent;
        border: 0;
        box-shadow: none;
        font-weight: normal;
        padding-left: 0;
        padding-right: 0;

        &:focus {
            box-shadow: none;
        }

        &:hover {
            color: $colorGrey90Alpha;
        }
    }

    &.primary {
        background-color: $colorRocheBlue;
        background-image: $gradientRocheBlue;
        box-shadow: 0 1px 4px 0 rgba(0,102,204,0.27), 0 2px 6px 0 rgba(0,102,204,0.40);
        color: #ffffff;

        &:hover {
            background-image: none;
            // border-color: $colorRocheBlueAlt;
        }

        &:active {
            box-shadow: none;
        }
    }

    &.trigger {
        background: transparent;
        border: 0;
        box-shadow: none;
        color: $colorGrey90Alpha;
        position: relative;
        z-index: 1;
    
        &:before {
            background-color: #e5e9f2;
            border-radius: 50%;
            content: '';
            display: block;
            height: 20px;
            left: 50%;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            transform-origin: 50% 50%;
            transition: all 150ms linear;
            width: 20px;
            z-index: -1;
        }

        &:hover, &:focus, &.triggered {
            color: $colorGrey90Alpha;
            box-shadow: none;

            &:before {
                height: 35px;
                opacity: 1;
                width: 35px;
            }
        }
    }
}

.base:not(.block) + .base:not(.block) {
    margin-left: .5rem;
}
.base:not(.block) + .base.link:not(.block) {
    margin-left: 1rem;
}
.base.link:not(.block) + .base:not(.block) {
    margin-left: 1rem;
}

.base.block + .base {
    margin-top: .5rem;
}

.densePadding { padding: .1875rem .6875rem; }
.compactPadding { padding: .375rem 1rem; }
.defaultPadding { padding: .6875rem 1.5rem; }
.paddedPadding { padding: 1rem 2rem; }
