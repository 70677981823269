@import '../../RbKit/config';

.thumbnail {
    @include elevate(2);
    background: $colorWhite;
    border-radius: 2px;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin: .5rem;
    padding-bottom: 56.25%;
    position: relative;
    width: 150px;

    .menu {
        bottom: 5px;
        position: absolute;
        right: 5px;
        z-index: 2;
    }
}

.placeholder {
    color: $colorGrey5;
    font-size: 4rem;
}
